import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

export default function HomePage() {
  return (
    <div className="min-h-screen bg-gray-800">
      <Navbar />
      <div className="flex flex-col text-center text-white min-h-80v justify-center items-center">
        <h1 className="text-4xl font-bold">A Dev's Helper.</h1>
        <h4 className="font-light mx-2">
          A developer helper bot that can make things easier.
        </h4>
        <div className="my-4">
          <Link
            to="/invite"
            className="bg-white p-2 text-gray-900 rounded border mx-1"
          >
            Invite
          </Link>
          <Link
            to="/commands"
            className="bg-white p-2 text-gray-900 rounded border mx-1"
          >
            Commands
          </Link>
        </div>
      </div>
    </div>
  );
}
