import React from "react";
import Navbar from "../components/Navbar";

const COMMANDS = [
  {
    command: "listlanguages",
    description: "Lists compiler languages.",
    usage: "?listlanguages",
  },
  {
    command: "runcode",
    description: "Runs a code.",
    usage: "Right click → Apps → Run Code",
  },
  { command: "about", description: "About the bot.", usage: "/about" },
  {
    command: "help",
    description: "Displays information about a command.",
    usage: "/help [command]",
  },
  {
    command: "invite",
    description: "Gets the bot invite link.",
    usage: "/invite",
  },
  {
    command: "ping",
    description: "Gets the bot ping.",
    usage: "/ping",
  },
  {
    command: "djs",
    description: "Searches discord.js documentation.",
    usage: "/djs <query>",
  },
  {
    command: "mdn",
    description: "Searches MDN Web Docs documentation.",
    usage: "/mdn <query>",
  },
  {
    command: "npm",
    description: "Searches NPM.",
    usage: "/npm <query>",
  },
  {
    command: "pip",
    description: "Searches PIP.",
    usage: "/pip <query>",
  },
];

export default function Commands() {
  return (
    <div className="min-h-screen bg-gray-800">
      <Navbar />
      <div className="flex flex-col text-center text-white justify-center min-h-80v items-center">
        <h1 className="text-4xl font-bold my-5">
          Commands{" "}
          <span className="hidden lg:-mx-4 lg:px-4 lg:overflow-x-auto lg:inline lg:pb-5">
            -
          </span>{" "}
          <br className="lg:hidden" />A Dev's Helper.
        </h1>
        <div className="hidden lg:-mx-4 lg:px-4 lg:overflow-x-auto lg:block lg:pb-5">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Command
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Usage
                  </th>
                </tr>
              </thead>
              <tbody>
                {COMMANDS.map((command) => (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div>
                          <p className="text-gray-900 whitespace-no-wrap">
                            {command.command}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {command.description.replace(/\n/gm, "<br />")}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-left">
                      <p className="text-gray-900 whitespace-no-wrap">
                        <code>{command.usage}</code>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-center bg-blue-darker w-full pb-5 lg:hidden">
          <div className="overflow-hidden bg-white rounded max-w-xs w-full shadow-lg leading-normal">
            {COMMANDS.map((command) => (
              <span className="block group p-4 border-b">
                <p className="font-bold text-lg mb-1 text-black text-left underline">
                  {command.command}
                </p>
                <p className="text-gray-700 mb-2 text-left">
                  <span className="font-bold text-black">Description:</span>{" "}
                  {command.description}
                  <br />
                  <span className="font-bold text-black">Usage:</span>{" "}
                  {command.usage}
                </p>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
