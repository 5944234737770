import React from "react";
import Navbar from "../components/Navbar";

export default function About() {
  return (
    <div className="min-h-screen bg-gray-800">
      <Navbar />
      <div className="flex flex-col text-center text-white min-h-80v justify-center items-center">
        <h1 className="text-4xl font-bold">A Dev's Helper.</h1>
        <p className="font-light mx-2">
          A simple Discord bot, made by{" "}
          <a
            className="underline text-theme-green hover:opacity-75 transition-opacity duration-300"
            href="https://murtajaziad.com"
          >
            Murtaja Ziad (iTzMurtaja#8951)
          </a>
          , to help you do your tasks (as a developer) easier :D
          <br />
          <br />
          Have an idea to improve the bot?
          <br />
          Contact me on the form on{" "}
          <a
            className="underline text-theme-green hover:opacity-75 transition-opacity duration-300"
            href="https://murtajaziad.com/contact"
          >
            my website
          </a>
          .
        </p>
      </div>
    </div>
  );
}
