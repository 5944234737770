import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Commands from "./pages/Commands";
import About from "./pages/About";
import NotFound from "./pages/404";
import "./styles/index.css";
import "./styles/tailwind.output.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/commands">
          <Commands />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route
          exact
          path="/invite"
          render={() =>
            (window.location.href =
              "https://discordapp.com/oauth2/authorize?client_id=614764810621419520&permissions=37215553&scope=bot%20applications.commands")
          }
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
