import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="flex flex-col lg:flex-row bg-gray-900">
      <div className="flex-1">
        <Link to="/">
          <img
            src="https://cdn.discordapp.com/avatars/614764810621419520/7380063531ffe6a5376307217480fb8e.png"
            width="64"
            height="64"
            alt="A dev's helper logo."
            className="mt-2 m-auto lg:mt-0 lg:m-unset"
          />
        </Link>
      </div>
      <div className="m-2 flex-1 justify-end self-center lg:m-0">
        <ul className="margin-0 list-none flex flex-row justify-end">
          <li className="mx-5">
            <Link
              to="/"
              className="text-white hover:text-opacity-75 hover:opacity-75 transition-opacity duration-300"
            >
              Home
            </Link>
          </li>
          <li className="mx-5">
            <Link
              to="/commands"
              className="text-white hover:text-opacity-75 hover:opacity-75 transition-opacity duration-300"
            >
              Commands
            </Link>
          </li>
          <li className="mx-5">
            <Link
              to="/about"
              className="text-white hover:text-opacity-75 hover:opacity-75 transition-opacity duration-300"
            >
              About
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
