import React from "react";
import Navbar from "../components/Navbar";

export default function NotFound() {
  return (
    <div className="min-h-screen bg-gray-800">
      <Navbar />
      <div className="flex flex-col text-center text-white min-h-80v justify-center items-center">
        <h1 className="text-4xl font-bold">404</h1>
        <p className="font-light mx-2">Not found.</p>
      </div>
    </div>
  );
}
